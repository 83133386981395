import {
  createCampaign,
  editCampaign,
  getCampaigns,
  getCampaign,
  deleteCampaigns,
  CampaignResponse,
  CampaignProps,
} from '@/admin/campaign';
import { reactive } from '@vue/composition-api';
import { usePayment } from '@/admin/payment';
import { DisplayDate, createDatePeriod, getTargetPlanNames } from '@/admin/util';

interface CampaignsDisplayData extends CampaignResponse {
  displayStatus: {
    text: string;
    value: string;
  };
  campaignPeriodDate: string;
  trialPeriod: string;
  subscriptionPlanNames: string[];
}
interface SelectSubscriptionPlan {
  subscriptionPlanId: string;
  subscriptionPlanName: string;
  price: string;
  status: string;
  order: number;
  isArchive: boolean;
  campaignCount: number;
}

const intervalOption = {
  day: {
    singular: '日額',
    multiple: '日間',
  },
  month: {
    singular: '月額',
    multiple: 'ヶ月',
  },
  year: {
    singular: '年額',
    multiple: '年間',
  },
};
class Campaigns {
  items: CampaignsDisplayData[] = [];
  plans: Option[] = [];

  init = async () => {
    const [campaigns, plans] = await Promise.all([getCampaigns(), usePayment().getSubscriptionPlans()]);
    this.plans = plans.map((plan) => ({
      text: plan.subscriptionPlanName,
      value: plan.subscriptionPlanId,
    }));
    this.items = campaigns.map((campaign) => {
      const subscriptionPlanNames = getTargetPlanNames(campaign.subscriptionPlanIds || [], plans);
      const campaignPeriodDate = createDatePeriod(campaign.campaignStartDate, campaign.campaignEndDate);
      const trialPeriod = `${campaign.intervalCount}${intervalOption[campaign.interval].multiple}`;
      const jstNow = new Date(Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000).getTime();
      const displayStatus = {
        text: '',
        value: '',
      };
      displayStatus.text = campaign.isArchive
        ? 'アーカイブ'
        : campaign.status === 'draft'
        ? '下書き'
        : jstNow < campaign.campaignStartDate
        ? '開始前'
        : campaign.campaignEndDate < jstNow
        ? '終了'
        : '公開';
      displayStatus.value = campaign.isArchive
        ? 'archive'
        : campaign.status === 'draft'
        ? 'draft'
        : jstNow < campaign.campaignStartDate
        ? 'reserve'
        : campaign.campaignEndDate < jstNow
        ? 'end'
        : 'release';
      return {
        ...campaign,
        subscriptionPlanNames,
        campaignPeriodDate,
        trialPeriod,
        displayStatus,
      };
    });
  };

  delete = async (id: string) => {
    await deleteCampaigns(id);
    const index = this.items.findIndex((item) => id === item.subscriptionCampaignId);
    if (index !== -1) this.items[index].isArchive = true;
  };
}

class Campaign {
  // campaign情報
  subscriptionCampaignId = '';
  subscriptionCampaignName = '';
  subscriptionCampaignType = 'trial';
  status: 'draft' | 'released' | '' = '';
  displayStatus = {
    text: '',
    value: '',
  };
  subscriptionPlanIds: string[] = [];
  interval: 'day' | 'month' | 'year' = 'day';
  intervalCount = 0;
  campaignStartDate = new DisplayDate();
  campaignEndDate = new DisplayDate();
  isReleasedSubscriptionCampaign = false;
  isArchive = false;
  // その他
  subscriptionPlans: SelectSubscriptionPlan[] = [];
  selectedPlans: SelectSubscriptionPlan[] = [];

  constructor(campaignId?: string) {
    if (campaignId) this.subscriptionCampaignId = campaignId;
  }
  init = async () => {
    const [campaign, plans, subscriptionProducts] = await Promise.all([
      getCampaign(this.subscriptionCampaignId),
      usePayment().getSubscriptionPlans(),
      usePayment().getSubscriptionProducts(),
    ]);
    this.subscriptionPlans = plans.map((plan) => {
      const jstNow = new Date(Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000).getTime();
      const price = plan.intervalCount
        ? `${intervalOption[plan.interval].singular} ${plan.price.toLocaleString()}円`
        : `${plan.intervalCount + intervalOption[plan.interval].multiple} ${plan.price.toLocaleString()}円`;
      const status = plan.isArchive
        ? 'アーカイブ'
        : plan.accessStartDate && jstNow < plan.accessStartDate
        ? '閲覧期間前'
        : plan.accessEndDate && plan.accessEndDate < jstNow
        ? '閲覧期間後'
        : 'アクティブ';
      const subscriptionProductName =
        subscriptionProducts.find((product) => plan.subscriptionProductId === product.subscriptionProductId)
          ?.subscriptionProductName || '';
      return {
        subscriptionPlanId: plan.subscriptionPlanId,
        subscriptionPlanName: plan.subscriptionPlanName,
        subscriptionProductName,
        price,
        status,
        order: plan.order,
        isArchive: plan.isArchive,
        campaignCount: plan.subscriptionCampaigns.length,
      };
    });
    if (campaign) {
      this.selectedPlans = (campaign.subscriptionPlanIds || []).reduce((acc, val) => {
        const plan = this.subscriptionPlans.find((subscriptionPlan) => val === subscriptionPlan.subscriptionPlanId);
        if (plan) acc.push(plan);
        return acc;
      }, [] as SelectSubscriptionPlan[]);
      this.subscriptionCampaignName = campaign.subscriptionCampaignName;
      this.subscriptionCampaignType = campaign.subscriptionCampaignType;
      this.status = campaign.status;
      this.subscriptionPlanIds = campaign.subscriptionPlanIds || [];
      this.interval = campaign.interval;
      this.intervalCount = campaign.intervalCount;
      this.interval = campaign.interval;
      this.campaignStartDate = new DisplayDate(campaign.campaignStartDate);
      this.campaignEndDate = new DisplayDate(campaign.campaignEndDate);
      this.isReleasedSubscriptionCampaign = campaign.isReleasedSubscriptionCampaign;
      this.isArchive = campaign.isArchive;
      const jstNow = new Date(Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000).getTime();
      this.displayStatus.text = campaign.isArchive
        ? 'アーカイブ'
        : campaign.status === 'draft'
        ? '下書き'
        : jstNow < campaign.campaignStartDate
        ? '開始前'
        : campaign.campaignEndDate < jstNow
        ? '終了'
        : '公開';
      this.displayStatus.value = campaign.isArchive
        ? 'archive'
        : campaign.status === 'draft'
        ? 'draft'
        : jstNow < campaign.campaignStartDate
        ? 'reserve'
        : campaign.campaignEndDate < jstNow
        ? 'end'
        : 'release';
    }
  };

  createProps = () => {
    let error = '';
    if (!this.subscriptionCampaignName) error += '・キャンペーン名は必須です\n';
    if (!this.subscriptionCampaignType) error += '・キャンペーンタイプは必須です\n';
    if (!this.status) error += '・ステータスは必須です\n';
    if (this.intervalCount < 1 || !this.interval) error += '・トライアル期間に不備があります\n';
    if (this.campaignStartDate.value === undefined || this.campaignEndDate.value === undefined)
      error += '・実施期間は必須です\n';
    else if (this.campaignStartDate.value > this.campaignEndDate.value)
      error += '・開始日時は終了日時より前に設定してください\n';
    else if (this.status === 'released' && !this.selectedPlans.length) error += '・プランを選択してください\n';
    if (error) throw error;
    return {
      subscriptionCampaignName: this.subscriptionCampaignName,
      subscriptionCampaignType: this.subscriptionCampaignType,
      status: this.status,
      subscriptionPlanIds: this.selectedPlans.filter((plan) => !plan.isArchive).map((plan) => plan.subscriptionPlanId),
      interval: this.interval,
      intervalCount: this.intervalCount,
      campaignStartDate: this.campaignStartDate.value,
      campaignEndDate: this.campaignEndDate.value,
    } as CampaignProps;
  };

  createCampaign = async () => {
    const props = this.createProps();
    await createCampaign(props);
  };
  saveCampaign = async () => {
    if (!this.subscriptionCampaignId) return;
    const props = this.createProps();
    await editCampaign(this.subscriptionCampaignId, props);
  };
}

export const useCampaigns = () => {
  const campaigns = new Campaigns();
  return { campaigns: reactive(campaigns) };
};

export const useCampaign = (campaignId?: string) => {
  const campaign = new Campaign(campaignId);
  return { campaign: reactive(campaign) };
};
