import core from '@/admin/core';

export interface CampaignProps {
  subscriptionCampaignName: string;
  subscriptionCampaignType: string; //現在はtrialのみ
  status: 'draft' | 'released';
  subscriptionPlanIds?: string[];
  interval: 'day' | 'month' | 'year';
  intervalCount: number;
  campaignStartDate: number;
  campaignEndDate: number;
}

export interface CampaignResponse extends CampaignProps {
  subscriptionCampaignId: string;
  isArchive: boolean;
  createDate: number;
  isReleasedSubscriptionCampaign: boolean; //すでにリリース済みかどうかのフラグ
}

export const createCampaign = async (props: CampaignProps) => {
  await core.httpClient.post('/admin/public/subscription/campaigns', props);
};

export const editCampaign = async (campaignId: string, props: CampaignProps) => {
  await core.httpClient.put(`/admin/public/subscription/campaigns/${campaignId}`, props);
};

export const getCampaigns = async () => {
  const result = await core.httpClient.get('/admin/public/subscription/campaigns');
  return result.data as CampaignResponse[];
};

export const getCampaign = async (campaignId?: string) => {
  if (!campaignId) return null;
  const result = await core.httpClient.get(`/admin/public/subscription/campaigns/${campaignId}`);
  return result.data as CampaignResponse;
};

export const deleteCampaigns = async (campaignId: string) => {
  await core.httpClient.delete(`/admin/public/subscription/campaigns/${campaignId}`);
};
