












































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useCampaign } from '@/composition/campaign';
import { getOptionsTitle } from '@/admin/util';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcDate from '@/components/FcDate.vue';
import FcNumberForm from '@/components/FcNumberForm.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Campaign',
  components: {
    FcDate,
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    campaignId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup({ campaignId }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('subscriptionCampaigns'));
    const { notify, error } = useNotification();
    const { confirmDialog } = useConfirm();
    const { campaign } = useCampaign(campaignId);
    const isFetchLoading = ref(true);
    const isSaving = ref(false);

    // tabの設定
    const tabs = ['キャンペーン設定', 'プラン選択'];
    const tab = ref(0);

    // plan選択テーブルの設定
    const headers = [
      { text: 'ステータス', value: 'status' },
      { text: 'グループ名', value: 'subscriptionProductName' },
      { text: 'プラン名', value: 'subscriptionPlanName' },
      { text: 'サブスク料金', value: 'price' },
      { text: '紐づくキャンペーン数', value: 'campaignCount' },
    ];
    const tableHeight = ref(450);

    campaign
      .init()
      .catch((e) => error(e))
      .finally(() => {
        isFetchLoading.value = false;
        // 初期描写時だけテーブルの高さ計算
        if (window.innerWidth > 599)
          tableHeight.value =
            campaign.subscriptionPlans.length < 6 ? (campaign.subscriptionPlans.length + 1) * 48 : 450;
      });

    const statusOptions = [
      {
        value: 'draft',
        text: '下書き',
      },
      {
        value: 'released',
        text: '公開',
      },
    ];
    const intervalOptions = [
      {
        value: 'day',
        text: '日',
      },
      {
        value: 'month',
        text: '月',
      },
      {
        value: 'year',
        text: '年',
      },
    ];

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (campaignId) {
          await campaign.saveCampaign();
          notify('変更しました。');
        } else {
          await campaign.createCampaign();
          notify('作成しました。');
          context.root.$router.push('/campaigns');
        }
      } catch (e) {
        //duplicateSubscriptionPlanIds: xxxx,xxxx
        const message = e instanceof Error ? e.message : (e as string);
        if (message.match(/^duplicateSubscriptionPlanIds:/)) {
          const planIds = message
            .replace(/^duplicateSubscriptionPlanIds:/, '')
            .split(',')
            .map((id) => id.trim());
          let planName = '';
          planIds.forEach((id) => {
            const plan = campaign.selectedPlans.find((selectedPlan) => id === selectedPlan.subscriptionPlanId);
            if (plan) planName += `・${plan.subscriptionPlanName}\n`;
          });
          error(`以下のプランで重複しています。\n${planName}`);
        } else error(e);
      }
      isSaving.value = false;
    };

    return {
      pageTitle: 'キャンペーン',
      myRoleSettings,
      campaign,
      getOptionsTitle,
      save,
      isFetchLoading,
      isSaving,
      statusOptions,
      intervalOptions,
      tabs,
      tab,
      headers,
      tableHeight,
    };
  },
});
